import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';

import Authentication from '../assets/images/authentication.svg';
import Logo from '../assets/icon/logo.svg';

function Signup() {
  const debugMode = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? true : false;

  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState(debugMode ? 'yishin70@gmail.com' : '');
  const [fullname, setFullname] = useState(debugMode ? 'chuck shin' : '');
  const [password, setPassword] = useState(debugMode ? '12345678' : '');
  const [newsletter, setNewsletter] = useState(true);

  const validEmail = /.+@.+\.[A-Za-z]+$/.test(email);
  const validFullname = fullname?.length >= 2 ? true : false;
  const validPassword = password?.length >= 8 ? true : false;

  const fetchSignup = () => {
    setLoading(true);

    const postData = {
      email,
      fullname,
      password,
    };

    fetch(`https://api.endp.info/api/v1/auth/signup`, {
      method: 'POST',
      cache: 'no-cache',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(postData),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json?.result === 'OK') {
          toast.success('Welcome to EndPoint!!!');
        } else {
          toast.error('Already registered user!');
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error('Server error!!!');
      })
      .then(() => {
        setLoading(false);
      });
  };

  const onClickSignUp = (e) => {
    e.preventDefault();

    if (validEmail && validFullname && validPassword) {
      fetchSignup();
    }
  };

  return (
    <main className="bg-white">
      <div className="relative md:flex">
        {/* Content */}
        <div className="md:w-full">
          <div className="min-h-screen h-full flex flex-col after:flex-1">
            {/* Header */}
            <div className="flex-1">
              <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
                {/* Logo */}
                <Link className="block" to="/">
                  <img src={Logo} width="32" height="32" alt="logo" />
                </Link>
              </div>
            </div>

            <div className="max-w-sm w-full mb-24 mx-auto px-4 py-8">
              <img className="mb-12" src={Authentication} alt="world" />

              <div className="w-full">
                <h1 className="text-3xl text-slate-800 font-bold mb-6">Create your Account ✨</h1>
              </div>
              {/* Form */}
              <form>
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium mb-1" htmlFor="email">
                      Email Address
                      <span className="text-rose-500">*</span>
                      {validEmail && <span className="ml-2 text-green-500">Valid email</span>}
                    </label>
                    <input
                      id="email"
                      className="form-input w-full"
                      type="email"
                      autoFocus
                      placeholder="input email address"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div>
                    <label className="block text-sm font-medium mb-1" htmlFor="name">
                      Full Name <span className="text-rose-500">*</span>
                      {validFullname && <span className="ml-2 text-green-500">Valid full name</span>}
                    </label>
                    <input
                      id="name"
                      className="form-input w-full"
                      type="text"
                      placeholder="input full name"
                      value={fullname}
                      onChange={(e) => setFullname(e.target.value)}
                    />
                  </div>
                  {/* <div>
                    <label className="block text-sm font-medium mb-1" htmlFor="role">
                      Your Role <span className="text-rose-500">*</span>
                    </label>
                    <select id="role" className="form-select w-full">
                      <option>Designer</option>
                      <option>Developer</option>
                      <option>Accountant</option>
                    </select>
                  </div> */}
                  <div>
                    <label className="block text-sm font-medium mb-1" htmlFor="password">
                      Password <span className="text-rose-500">*</span>
                      {validPassword && <span className="ml-2 text-green-500">Valid password</span>}
                    </label>
                    <input
                      id="password"
                      className="form-input w-full"
                      type="password"
                      autoComplete="off"
                      placeholder="input password (more 8 digit)"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                </div>
                <div className="flex items-center justify-between mt-6">
                  <div className="mr-1">
                    <label className="flex items-center">
                      <input
                        type="checkbox"
                        className="form-checkbox"
                        checked={newsletter}
                        onChange={(e) => setNewsletter(e.target.checked)}
                      />
                      <span className="text-sm ml-2">Email me about our service news.</span>
                    </label>
                  </div>
                  <button
                    className={
                      validEmail && validFullname && validPassword
                        ? 'btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 whitespace-nowrap cursor-pointer'
                        : 'btn bg-gray-500 hover:bg-gray-600 text-white ml-3 whitespace-nowrap'
                    }
                    disabled={validEmail && validFullname && validPassword ? false : true}
                    onClick={onClickSignUp}>
                    Sign Up
                  </button>
                </div>
              </form>
              {/* Footer */}
              <div className="pt-5 mt-6 border-t border-slate-200">
                <div className="text-sm">
                  Have an account?{' '}
                  <Link className="font-medium text-indigo-500 hover:text-indigo-600" to="/signin">
                    Sign In
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Toaster />
    </main>
  );
}

export default Signup;
