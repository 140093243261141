import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';

// import { GoogleLogin } from 'react-google-login';

import AppContext from '../utils/AppContext';

// import RealtimeAnalytics from "../assets/images/realtime_analytics.svg";
import ConnectedWorld from '../assets/images/connected_world.svg';

import Logo from '../assets/icon/logo.svg';
// import KakaoIcon from "../assets/icon/kakao-icon.svg";
// import NaverIcon from "../assets/icon/naver-icon.svg";
import GoogleIcon from '../assets/icon/google-icon.svg';
// import AppleIcon from "../assets/icon/apple-icon.svg";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faGoogle } from "@fortawesome/free-brands-svg-icons";

function Signin() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [loading, setLoading] = useState(false);
  const { setUserInfo } = useContext(AppContext);

  const validEmail = /.+@.+\.[A-Za-z]+$/.test(email);
  const validPassword = password?.length >= 8 ? true : false;

  const fetchSignIn = () => {
    setLoading(true);

    const postData = {
      email,
      password,
    };

    fetch(`https://api.endp.info/api/v1/auth/signin`, {
      method: 'PUT',
      cache: 'no-cache',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(postData),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.result === 'OK') {
          toast.success('Welcome to EndPoint!!!');
          setUserInfo(json.data);
          window.location.href = '/endp/device';
        } else {
          toast.error('Check your ID or password!');
          setUserInfo(null);
        }
      })
      .catch((error) => {
        console.error(error);
        toast.error('Server error!!!');
      })
      .then(() => {
        setLoading(false);
      });
  };

  const onClickSignIn = (e) => {
    e.preventDefault();

    if ((validEmail, validPassword)) {
      fetchSignIn();
    }
  };

  const responseGoogle = (response) => {
    console.log(response);
  };

  return (
    <main className="bg-white">
      <div className="relative md:flex">
        {/* Content */}
        <div className="md:w-full">
          <div className="min-h-screen h-full flex flex-column after:flex-1 align-middle">
            {/* Header */}
            <div className="flex-1">
              <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
                {/* Logo */}
                <Link className="block" to="/">
                  <img src={Logo} width="32" height="32" alt="logo" />
                </Link>
              </div>
            </div>

            <div className="flex max-w-sm w-full h-screen items-center mx-auto px-4 py-8">
              <div>
                <img
                  // className="object-cover object-center w-full h-full"
                  src={ConnectedWorld}
                  alt="world"
                />

                <div className="w-full mt-6">
                  <h1 className="text-3xl text-slate-800 font-bold mb-6">{'EndPoint Signin ✨              '}</h1>
                </div>
                {/* Form */}
                <form>
                  <div className="space-y-4">
                    <div>
                      <label className="block text-sm font-medium mb-1" htmlFor="email">
                        EMail
                        <span className="text-rose-500">*</span>
                        {validEmail && <span className="ml-2 text-green-500">Valid full name</span>}
                      </label>
                      <input
                        id="email"
                        className="form-input w-full"
                        type="email"
                        placeholder="input email address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                    <div>
                      <label className="block text-sm font-medium mb-1" htmlFor="password">
                        Password
                        <span className="text-rose-500">*</span>
                        {validEmail && <span className="ml-2 text-green-500">Valid full name</span>}
                      </label>
                      <input
                        id="password"
                        className="form-input w-full"
                        type="password"
                        autoComplete="off"
                        placeholder="input password (more 8 digit)"
                        value={password}
                        onInput={(e) => setPassword(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="flex items-center justify-between mt-6">
                    <div className="mr-1">
                      <Link className="text-sm underline hover:no-underline" to="/reset-password">
                        Forgot Password?
                      </Link>
                    </div>
                    <button
                      className={
                        validEmail && validPassword
                          ? 'btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 whitespace-nowrap cursor-pointer'
                          : 'btn bg-gray-500 hover:bg-gray-600 text-white ml-3 whitespace-nowrap'
                      }
                      disabled={validEmail && validPassword ? false : true}
                      onClick={onClickSignIn}>
                      SignIn
                    </button>
                  </div>
                </form>

                {/* Footer */}
                <div className="pt-5 mt-6 border-t border-slate-200">
                  <div className="text-sm">
                    Don’t you have an account?
                    <Link className="ml-2 font-medium text-indigo-500 hover:text-indigo-600" to="/signup">
                      Sign Up
                    </Link>
                  </div>
                  {/* Warning */}
                  {/* <div className="mt-5">
                  <div className="bg-yellow-100 text-yellow-600 px-3 py-2 rounded">
                    <svg
                      className="inline w-3 h-3 shrink-0 fill-current mr-2"
                      viewBox="0 0 12 12"
                    >
                      <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                    </svg>
                    <span className="text-sm">
                      아직은 계정로그인을 지원하지 않습니다.
                      <br />
                      아래의 소셜 로그인 버튼을 이용하여 주십시오.
                      <br />
                    </span>
                  </div>
                </div>
              </div> */}

                  {/* social login */}
                  {/* <div className="mt-6 pt-2 border-t border-slate-200"> */}
                  {/* kakao */}
                  {/* <a
                  href={
                    process.env.NODE_ENV === "production"
                      ? "/oauth/kakao"
                      : "https://localhost:3000/oauth/kakao"
                  }
                >
                  <button className="uppercase h-12 mt-3 text-white w-full rounded bg-[#FFE812] hover:bg-yellow-300">
                    <div className="flex flex-row justify-center text-black">
                      <img
                        className="mr-2"
                        src={KakaoIcon}
                        width="24"
                        height="24"
                        alt="kakao login"
                      />
                      카카오 계정으로 로그인
                    </div>
                  </button>
                </a> */}
                  {/* Naver */}
                  {/* <a
                  href={
                    process.env.NODE_ENV === "production"
                      ? "/oauth/naver"
                      : "https://localhost:3000/oauth/naver"
                  }
                >
                  <button className="uppercase h-12 mt-3 text-white w-full rounded bg-[#2DB400] hover:bg-green-500">
                    <div className="flex flex-row justify-center text-black">
                      <img
                        className="mr-2"
                        src={NaverIcon}
                        width="24"
                        height="24"
                        alt="naver login"
                      />
                      네이버 계정으로 로그인
                    </div>
                  </button>
                </a> */}

                  {/* Google */}
                  {/* <a href={process.env.NODE_ENV === 'production' ? '/oauth/google' : 'https://localhost.endp.info:8443/oauth/google'}> */}
                  {/* <GoogleLogin
                  clientId="1011461783412-2clt3kktumnsmq8mh9h1enm6j2msr1am.apps.googleusercontent.com"
                  render={(renderProps) => (
                    <button className="uppercase h-12 mt-3 text-white w-full rounded border border-gray-400 bg-white hover:bg-gray-100">
                      {/* <FontAwesomeIcon icon={faGoogle} className="mr-2"/> */}
                  {/*}         <div className="flex flex-row justify-center text-black">
                        <img className="mr-2" src={GoogleIcon} width="24" height="24" alt="google login" />
                        {'Sign in with Google'}
                      </div>
                    </button>
                  )}
                  buttonText="Login"
                  onSuccess={responseGoogle}
                  onFailure={responseGoogle}
                  // cookiePolicy={'single_host_origin'}
                /> */}
                  {/* </a> */}
                  {/* <a
                  href={
                    process.env.NODE_ENV === "production"
                      ? "/oauth/apple"
                      : "https://localhost:3000/oauth/apple"
                  }
                >
                  <button className="uppercase h-12 mt-3 text-white w-full rounded border border-gray-400 bg-white hover:bg-gray-100">
                    <div className="flex flex-row justify-center text-black">
                      <img
                        className="mr-2"
                        src={AppleIcon}
                        width="24"
                        height="24"
                        alt="apple login"
                      />
                      애플 계정으로 로그인
                    </div>
                  </button>
                </a> */}
                </div>

                {/* information */}
                <div className="pt-5 mt-6 border-t border-slate-200">
                  <div className="bg-yellow-100 text-yellow-600 px-3 py-2 rounded">
                    <svg className="inline w-3 h-3 shrink-0 fill-current mr-2" viewBox="0 0 12 12">
                      <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                    </svg>
                    <span className="text-sm">
                      {'If you sign in with social account, you’re considered to accept the membership and terms of use of this site.'}
                      <br />
                    </span>

                    {/* <svg
                    className="inline w-3 h-3 shrink-0 fill-current mr-2"
                    viewBox="0 0 12 12"
                  >
                    <path d="M10.28 1.28L3.989 7.575 1.695 5.28A1 1 0 00.28 6.695l3 3a1 1 0 001.414 0l7-7A1 1 0 0010.28 1.28z" />
                  </svg>
                  <span className="text-sm text-red-400">
                    업무 관계자만 로그인이 가능하며 최초 로그인후 관리자 승인에
                    1~2일의 시간이 소요될 수 있습니다.
                    <br />
                  </span> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Image */}
        {/* <div
          className="hidden md:block absolute top-0 bottom-0 right-0 md:w-1/2 bg-gray-400"
          aria-hidden="true"
        >
          <img
            className="object-cover object-center w-full h-full"
            src={ConnectedWorld}
            alt="image"
          />
        </div> */}
      </div>

      <Toaster />
    </main>
  );
}

export default Signin;
