import React, { useState, useEffect, useRef } from 'react';
import { XTerm } from 'xterm-for-react';

function TelnetClient() {
  const xtermRef = useRef(null);

  React.useEffect(() => {
    document.title = 'telnet';

    // You can call any method in XTerm.js by using 'xterm xtermRef.current.terminal.[What you want to call]
    xtermRef.current.terminal.writeln('Welcome... Endp.info terminal v1.0\n');
  }, []);

  return (
    <div className="flex h-screen w-full overflow-hidden">
      <XTerm ref={xtermRef} className="h-screen w-full" />
    </div>
  );
}

export default TelnetClient;
