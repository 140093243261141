import React, { useState, useEffect, useContext } from 'react';

import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';

import AppContext from '../utils/AppContext';
import DataTable from 'react-data-table-component';

function EndpLog() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [deviceList, setDeviceList] = useState([]);
  const [selectDeviceId, setSelectDeviceId] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);

  const { userInfo, setUserInfo } = useContext(AppContext);

  const num2dot = (num) => {
    var d = num % 256;
    for (var i = 3; i > 0; i--) {
      num = Math.floor(num / 256);
      d = (num % 256) + '.' + d;
    }
    return d;
  };

  const fetchDeviceAll = () => {
    if (!userInfo?.teamIdx) return;

    setLoading(true);

    fetch(`https://api.endp.info/api/v1/endp/device?teamIdx=${userInfo.teamIdx}&pageCount=9999&pageIndex=1`)
      .then((res) => res.json())
      .then((json) => {
        if (json?.result === 'OK') {
          setDeviceList(json.data);
        } else {
          setDeviceList([]);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .then(() => setLoading(false));
  };

  const onDeviceChange = (e) => {
    setSelectDeviceId(e.target.value);
  };

  useEffect(() => {
    fetchLogPage(1);
  }, [selectDeviceId]);

  const fetchLogPage = (page) => {
    setLoading(true);

    fetch(`https://api.endp.info/api/v1/endp/log?deviceIdx=${selectDeviceId}&pageCount=${perPage}&pageIndex=${page}`)
      .then((res) => res.json())
      .then((json) => {
        if (json?.result === 'OK') {
          setTotalRows(json.totalCount);
          setData(json.data);
        } else {
          setData([]);
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const onChangeRowsPerPage = async (newPerPage, page) => {
    setLoading(true);

    setPerPage(newPerPage);
    fetchLogPage(page);

    setLoading(false);
  };

  useEffect(() => {
    fetchDeviceAll();
  }, []);

  const columns = [
    {
      name: 'No',
      selector: (row) => row.ddidx,
      sortable: true,
      compact: true,
      center: true,
      minWidth: 10,
    },
    // {
    //   name: 'Device ID',
    //   selector: (row) => row.didx,
    //   sortable: true,
    //   compact: true,
    // },
    {
      name: 'CPU',
      selector: (row) => row.cpu || 0,
      sortable: true,
      compact: true,
    },
    {
      name: 'Memory',
      selector: (row) => row.memory || 0,
      sortable: true,
      compact: true,
    },
    {
      name: 'Disk',
      selector: (row) => row.disk || 0,
      sortable: true,
      compact: true,
    },
    {
      name: 'Network',
      selector: (row) => row.network || 0,
      sortable: true,
      compact: true,
    },
    {
      name: 'Date',
      selector: (row) => row.created,
      sortable: true,
      compact: true,
    },
  ];

  const conditionalRowStyles = [
    {
      when: (row, index) => true, //row.mdn && row.mdn !== '',
      style: (row, index) => ({ backgroundColor: index % 2 === 0 ? '#d0d0ff' : 'transparent' }),
    },
  ];

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* breadcrumb */}
            <nav className="flex mb-4" aria-label="Breadcrumb">
              <ol className="inline-flex items-center space-x-1 md:space-x-3">
                <li className="inline-flex items-center">
                  <a
                    href="#"
                    className="inline-flex items-center text-sm text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                    <svg className="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
                    </svg>
                    Home
                  </a>
                </li>
                <li>
                  <div className="flex items-center">
                    <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"></path>
                    </svg>
                    <a
                      href="#"
                      className="ml-1 text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">
                      {'EndPoint Nodes'}
                    </a>
                  </div>
                </li>
                <li aria-current="page">
                  <div className="flex items-center">
                    <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"></path>
                    </svg>
                    <span className="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">{'Device Log'}</span>
                  </div>
                </li>
              </ol>
            </nav>

            <div>
              <select onChange={onDeviceChange} value={selectDeviceId}>
                <option value={0} key={0}>
                  {'Select Device'}
                </option>
                {deviceList.map((item) => (
                  <option value={item.didx} key={item.didx}>
                    {item.model + ' : ' + item.deviceId}
                  </option>
                ))}
              </select>
            </div>

            {/* servers */}
            <DataTable
              columns={columns}
              data={data}
              progressPending={loading}
              defaultSortFieldId={1}
              defaultSortAsc={false}
              conditionalRowStyles={conditionalRowStyles}
              pagination
              paginationServer={true}
              paginationTotalRows={totalRows}
              paginationPerPage={perPage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              onChangePage={fetchLogPage}
              // expandableRows
            />
          </div>
        </main>

        {/* <Banner /> */}
      </div>
    </div>
  );
}

export default EndpLog;
