import CryptoJS, { enc } from 'crypto-js';

const getItem = (item) => {
  return JSON.parse(localStorage.getItem(item));

  // const encrypted = sessionStorage.getItem(item);
  // if (!encrypted) return null;

  // const decrypted = CryptoJS.AES.decrypt(encrypted, process.env.ENCRYPT_STORAGE_SECRET_KEY);

  // const decryptedString = decrypted.toString(enc.Utf8);

  // return JSON.parse(decryptedString);
};

const setItem = (item, data) => {
  localStorage.setItem(item, data);
  // try {
  //   const input = typeof data === 'object' ? JSON.stringify(data) : data;
  //   const encrypted = CryptoJS.AES.encrypt(input, process.env.ENCRYPT_STORAGE_SECRET_KEY).toString()();
  //   sessionStorage.setItem(item, encrypted);
  // } catch (error) {
  //   // console.log(error);
  // }
};

const EncryptStorage = { getItem, setItem };

export default EncryptStorage;
