import React, { useState, useEffect, useContext } from 'react';

import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';
import ModalBasic from '../components/ModalBasic';
import ModalBlank from '../components/ModalBlank';
import toast, { Toaster } from 'react-hot-toast';
import AppContext from '../utils/AppContext';

import DataTable from 'react-data-table-component';

function MyTeam() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(20);

  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const [selectedRows, setSelectedRows] = useState([]);
  const [teamIndex, setTeamIndex] = useState('');
  const [inputName, setInputName] = useState('');
  const [inputMemo, setInputMemo] = useState('');
  const [isNewTeam, setIsNewTeam] = useState();

  const { userInfo, setUserInfo } = useContext(AppContext);

  useEffect(() => {
    fetchTeamPage(1);
  }, [userInfo]);

  const fetchTeamPage = (page) => {
    if (!userInfo?.teamIdx) return;

    setLoading(true);

    fetch(`https://api.endp.info/api/v1/team/myteam?teamIdx=${userInfo.teamIdx}&pageCount=${perPage}&pageIndex=${page}`)
      .then((res) => res.json())
      .then((json) => {
        if (json?.result === 'OK') {
          setTotalRows(json.totalCount);
          setData(json.data);
        } else {
          setData([]);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .then(() => {
        setLoading(false);
      });
  };

  const fetchTeamNew = () => {
    setLoading(true);

    const postData = {
      userIdx: userInfo.uidx,
      name: inputName,
      memo: inputMemo,
    };

    fetch(`https://api.endp.info/api/v1/team`, {
      method: 'POST',
      cache: 'no-cache',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(postData),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.result === 'OK') {
          toast.success('Successfully saved new team!');
          setEditModalOpen(false);

          const newInfo = { ...userInfo, teamIdx: json.tidx };
          setUserInfo(newInfo);
        } else {
          toast.error('Failed to save. Check the fields!');
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        toast.error('Failed to saved new team...');
      });
  };

  const fetchTeamEdit = () => {
    setLoading(true);

    const row = selectedRows[0];

    const postData = {
      name: inputName,
      memo: inputMemo,
    };

    fetch(`https://api.endp.info/api/v1/team/${row.tidx}`, {
      method: 'PUT',
      cache: 'no-cache',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(postData),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json?.result === 'OK') {
          setEditModalOpen(false);
          toast.success('Successfully save team!');

          fetchTeamPage(1);
        } else {
          toast.error('Failed modify to team information. alread exiest team.');
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        toast.error('Failed modify to team information');
      });
  };

  const fetchTeamDelete = () => {
    setLoading(true);

    const row = selectedRows[0];

    fetch(`https://api.endp.info/api/v1/team/${row.tidx}`, {
      method: 'DELETE',
      cache: 'no-cache',
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.result === 'OK') {
          setDeleteModalOpen(false);
          toast.success('Successfully save team!');

          fetchTeamPage(1);
        } else {
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        toast.error('Failed to saved new team...');
      });
  };

  const onChangeRowsPerPage = async (newPerPage, page) => {
    setLoading(true);

    setPerPage(newPerPage);
    fetchTeamPage(page);

    setLoading(false);
  };

  const onSelectedRowsChange = (state) => {
    setSelectedRows(state.selectedRows);
  };

  useEffect(() => {
    // fetchTeamPage(1);
  }, []);

  const columns = [
    {
      name: 'No',
      selector: (row) => row.tidx,
      sortable: true,
      compact: true,
      center: true,
    },
    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: true,
      compact: true,
    },
    {
      name: 'Description',
      selector: (row) => row.memo,
      sortable: true,
      wrap: true,
      compact: true,
    },
    {
      name: 'Created',
      selector: (row) => row.created,
      sortable: true,
      wrap: true,
      compact: true,
    },
    {
      name: 'Modified',
      selector: (row) => row.modified,
      sortable: true,
      compact: true,
    },
  ];

  const conditionalRowStyles = [
    {
      when: (row) => false,
      style: (row) => ({ backgroundColor: row.mdn ? '#94d35060' : '#ffddde60' }),
    },
  ];

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* breadcrumb */}
            <nav className="flex mb-4" aria-label="Breadcrumb">
              <ol className="inline-flex items-center space-x-1 md:space-x-3">
                <li className="inline-flex items-center">
                  <a
                    href="#"
                    className="inline-flex items-center text-sm text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                    <svg className="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
                    </svg>
                    {'Home'}
                  </a>
                </li>
                <li>
                  <div className="flex items-center">
                    <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"></path>
                    </svg>
                    <a
                      href="#"
                      className="ml-1 text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">
                      {'Team'}
                    </a>
                  </div>
                </li>
                <li aria-current="page">
                  <div className="flex items-center">
                    <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"></path>
                    </svg>
                    <span className="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">{'My Team'}</span>
                  </div>
                </li>
              </ol>
            </nav>

            {/* button */}
            <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2 mr-4">
              {/* <div className="flex flex-wrap items-right -m-1.5"> */}
              {data && data.length === 0 && (
                <button
                  className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
                  aria-controls="edit-modal"
                  onClick={(e) => {
                    e.stopPropagation();

                    setIsNewTeam(true);

                    setTeamIndex('');
                    setInputName('');
                    setInputMemo('');

                    setEditModalOpen(true);
                  }}>
                  <svg className="w-4 h-4 fill-current opacity-50 shrink-0" viewBox="0 0 16 16">
                    <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                  </svg>
                  <span className="ml-2">Add</span>
                </button>
              )}
              <button
                className="btn border-slate-200 hover:border-slate-300 text-slate-600"
                aria-controls="edit-modal"
                onClick={(e) => {
                  e.stopPropagation();

                  if (selectedRows.length !== 1) {
                    toast.error('Need select only 1 row');
                    return;
                  }

                  setIsNewTeam(false);

                  const row = selectedRows[0];
                  setTeamIndex(row.tidx || '');
                  setInputName(row.name || '');
                  setInputMemo(row.memo || '');

                  setEditModalOpen(true);
                }}>
                <svg className="w-4 h-4 fill-current text-slate-500 shrink-0" viewBox="0 0 16 16">
                  <path d="M11.7.3c-.4-.4-1-.4-1.4 0l-10 10c-.2.2-.3.4-.3.7v4c0 .6.4 1 1 1h4c.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4l-4-4zM4.6 14H2v-2.6l6-6L10.6 8l-6 6zM12 6.6L9.4 4 11 2.4 13.6 5 12 6.6z" />
                </svg>
                <span className="ml-2">Edit</span>
              </button>

              <ModalBasic
                id="edit-modal"
                modalOpen={editModalOpen}
                setModalOpen={setEditModalOpen}
                title={isNewTeam ? '✨ New Team' : '✏️ Edit Team'}>
                {/* Modal content */}
                <div className="px-5 py-4">
                  <div className="text-sm">
                    {!isNewTeam && (
                      <div className="font-medium text-slate-400 mb-2">
                        {/* <label className="block text-sm font-medium mb-1" htmlFor="inputIndex"> */}
                        Team index
                        {/* </label> */}
                        <input
                          id="inputIndex"
                          className="form-input w-full disabled:border-slate-200 disabled:bg-slate-100 disabled:text-slate-400 disabled:cursor-not-allowed"
                          type="text"
                          value={teamIndex}
                          disabled
                        />
                      </div>
                    )}

                    <div className="space-y-2 font-medium text-slate-400 mb-2">
                      Team Name
                      <input
                        id="inputName"
                        className="form-input w-full"
                        type="text"
                        value={inputName}
                        onChange={(e) => setInputName(e.target.value)}
                      />
                    </div>

                    <div className="space-y-2 font-medium text-slate-400 mb-2">
                      Description:
                      <textarea
                        id="default"
                        className="form-input w-full"
                        type="textarea"
                        value={inputMemo}
                        onChange={(e) => setInputMemo(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                {/* Modal footer */}
                <div className="sticky bottom-0 px-5 py-4 bg-white border-t border-slate-200">
                  <div className="flex flex-wrap justify-end space-x-2">
                    <button
                      className="btn-sm border-slate-200 hover:border-slate-300 text-slate-600"
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditModalOpen(false);
                      }}>
                      Close
                    </button>
                    <button
                      className="btn-sm bg-indigo-500 hover:bg-indigo-600 text-white"
                      onClick={(e) => {
                        e.stopPropagation();

                        if (!inputName) {
                          toast.error('input need model value');
                          return;
                        }

                        if (isNewTeam) fetchTeamNew();
                        else fetchTeamEdit();
                      }}>
                      {'Save'}
                    </button>
                  </div>
                </div>
              </ModalBasic>

              <button
                className="btn border-slate-200 hover:border-slate-300 text-rose-500"
                aria-controls="danger-modal"
                onClick={(e) => {
                  e.stopPropagation();
                  setDeleteModalOpen(true);
                }}>
                <svg className="w-4 h-4 fill-current shrink-0" viewBox="0 0 16 16">
                  <path d="M5 7h2v6H5V7zm4 0h2v6H9V7zm3-6v2h4v2h-1v10c0 .6-.4 1-1 1H2c-.6 0-1-.4-1-1V5H0V3h4V1c0-.6.4-1 1-1h6c.6 0 1 .4 1 1zM6 2v1h4V2H6zm7 3H3v9h10V5z" />
                </svg>
                <span className="ml-2">Delete</span>
              </button>
              <ModalBlank id="delete-modal" modalOpen={deleteModalOpen} setModalOpen={setDeleteModalOpen}>
                <div className="p-5 flex space-x-4">
                  {/* Icon */}
                  <div className="w-10 h-10 rounded-full flex items-center justify-center shrink-0 bg-rose-100">
                    <svg className="w-4 h-4 shrink-0 fill-current text-rose-500" viewBox="0 0 16 16">
                      <path d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 12c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm1-3H7V4h2v5z" />
                    </svg>
                  </div>
                  {/* Content */}
                  <div>
                    {/* Modal header */}
                    <div className="mb-2">
                      <div className="text-lg font-semibold text-slate-800">Delete Selected Team?</div>
                    </div>
                    {/* Modal content */}
                    <div className="text-sm mb-10">
                      <div className="space-y-2">
                        {/* <p>Semper eget duis at tellus at urna condimentum mattis pellentesque lacus suspendisse faucibus interdum.</p> */}
                      </div>
                    </div>
                    {/* Modal footer */}
                    <div className="flex flex-wrap justify-end space-x-2">
                      <button
                        className="btn-sm border-slate-200 hover:border-slate-300 text-slate-600"
                        onClick={(e) => {
                          e.stopPropagation();
                          setDeleteModalOpen(false);
                        }}>
                        {'Cancel'}
                      </button>
                      <button
                        className="btn-sm bg-rose-500 hover:bg-rose-600 text-white"
                        onClick={(e) => {
                          e.stopPropagation();

                          if (selectedRows.length !== 1) {
                            alert('Need select only 1 row');
                            return;
                          }

                          fetchTeamDelete();
                        }}>
                        {'Yes'}
                      </button>
                    </div>
                  </div>
                </div>
              </ModalBlank>
            </div>

            {/* Team List */}
            <DataTable
              columns={columns}
              data={data}
              progressPending={loading}
              // defaultSortFieldId={1}
              // defaultSortAsc={false}
              conditionalRowStyles={conditionalRowStyles}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationPerPage={perPage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              onChangePage={fetchTeamPage}
              selectableRows={true}
              onSelectedRowsChange={onSelectedRowsChange}
              selectableRowsVisibleOnly={true}
              selectableRowsSingle={true}
            />
          </div>
        </main>
      </div>
    </div>
  );
}

export default MyTeam;
