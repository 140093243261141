import { ReactNode } from "react";

const Tooltip = ({ message, children }) => {
  return (
    <div className="relative flex flex-col items-center group">
      {children}
      <div className="absolute bottom-0 flex flex-col items-center hidden mb-6 group-hover:flex">
        <span className="relative w-20 z-10 p-1 text-center text-xs leading-none text-black bg-white shadow-lg rounded-md">{message}</span>
        <div className="w-3 h-3 -mt-2 rotate-45 bg-gray-300"></div>
      </div>
    </div>
  );
};

export default Tooltip;