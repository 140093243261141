import React, { useState, useEffect, useContext } from 'react';

import Sidebar from '../partials/Sidebar';
import Header from '../partials/Header';

import AppContext from '../utils/AppContext';
import DataTable from 'react-data-table-component';

function Monitoring() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(20);

  const [selectedRows, setSelectedRows] = useState(undefined);

  const { userInfo, setUserInfo } = useContext(AppContext);

  const fetchTeamPage = (page) => {
    if (!userInfo?.teamIdx) return;

    setLoading(true);

    fetch(`https://api.endp.info/api/v1/team/users?teamIdx=${userInfo.teamIdx}&pageCount=${perPage}&pageIndex=${page}`)
      .then((res) => res.json())
      .then((json) => {
        if (json?.result === 'OK') {
          setTotalRows(json.totalCount);
          setData(json.data);
        } else {
          setData([]);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .then(() => {
        setLoading(false);
      });
  };

  const onChangeRowsPerPage = async (newPerPage, page) => {
    setLoading(true);

    setPerPage(newPerPage);
    fetchTeamPage(page);

    setLoading(false);
  };

  const onSelectedRowsChange = (state) => {
    setSelectedRows(state.selectedRows);
  };

  const onClickRemote = (remoteIp, remotePort) => {
    window.open(`telnet://${remoteIp}:${remotePort}`, '_blank', 'noopener');
  };

  useEffect(() => {
    fetchTeamPage(1);
  }, []);

  const columns = [
    {
      name: 'Index',
      selector: (row) => row.uidx,
      sortable: true,
      compact: true,
      center: true,
    },
    {
      name: 'Name',
      selector: (row) => row.fullname,
      sortable: true,
      compact: true,
    },
    {
      name: 'Email',
      selector: (row) => row.email,
      sortable: true,
      compact: true,
    },
    {
      name: 'Invited',
      selector: (row) => (row.created ? row.created : 'Pending...'),
      sortable: true,
      wrap: true,
      compact: true,
    },
  ];

  const conditionalRowStyles = [
    {
      when: (row) => false, //row.mdn && row.mdn !== '',
      style: (row) => ({ backgroundColor: row.mdn ? '#94d35060' : '#ffddde60' }),
    },
  ];

  return (
    <div className="flex h-screen overflow-hidden">
      {/* Sidebar */}
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      {/* Content area */}
      <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
        {/*  Site header */}
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        <main>
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            {/* breadcrumb */}
            <nav className="flex mb-4" aria-label="Breadcrumb">
              <ol className="inline-flex items-center space-x-1 md:space-x-3">
                <li className="inline-flex items-center">
                  <a
                    href="#"
                    className="inline-flex items-center text-sm text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-white">
                    <svg className="mr-2 w-4 h-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
                    </svg>
                    {'Home'}
                  </a>
                </li>
                <li>
                  <div className="flex items-center">
                    <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"></path>
                    </svg>
                    <a
                      href="#"
                      className="ml-1 text-sm font-medium text-gray-700 hover:text-gray-900 md:ml-2 dark:text-gray-400 dark:hover:text-white">
                      {'Team'}
                    </a>
                  </div>
                </li>
                <li aria-current="page">
                  <div className="flex items-center">
                    <svg className="w-6 h-6 text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fill-rule="evenodd"
                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                        clipRule="evenodd"></path>
                    </svg>
                    <span className="ml-1 text-sm font-medium text-gray-400 md:ml-2 dark:text-gray-500">{'Team Users'}</span>
                  </div>
                </li>
              </ol>
            </nav>

            {/* button */}
            <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2 mr-4">
              {/* <div className="flex flex-wrap items-right -m-1.5"> */}
              <button className="btn bg-indigo-500 hover:bg-indigo-600 text-white">
                <svg className="w-4 h-4 fill-current opacity-50 shrink-0" viewBox="0 0 16 16">
                  <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                </svg>
                <span className="ml-2">{'Invite'}</span>
              </button>

              {/* <button className="btn border-slate-200 hover:border-slate-300 text-slate-600">
            <svg className="w-4 h-4 fill-current text-slate-500 shrink-0" viewBox="0 0 16 16">
              <path d="M11.7.3c-.4-.4-1-.4-1.4 0l-10 10c-.2.2-.3.4-.3.7v4c0 .6.4 1 1 1h4c.3 0 .5-.1.7-.3l10-10c.4-.4.4-1 0-1.4l-4-4zM4.6 14H2v-2.6l6-6L10.6 8l-6 6zM12 6.6L9.4 4 11 2.4 13.6 5 12 6.6z" />
            </svg>
            <span className="ml-2">Edit Content</span>
          </button> */}

              <button className="btn border-slate-200 hover:border-slate-300 text-rose-500">
                <svg className="w-4 h-4 fill-current shrink-0" viewBox="0 0 16 16">
                  <path d="M5 7h2v6H5V7zm4 0h2v6H9V7zm3-6v2h4v2h-1v10c0 .6-.4 1-1 1H2c-.6 0-1-.4-1-1V5H0V3h4V1c0-.6.4-1 1-1h6c.6 0 1 .4 1 1zM6 2v1h4V2H6zm7 3H3v9h10V5z" />
                </svg>
                <span className="ml-2">Delete</span>
              </button>
            </div>

            {/* <Banner /> */}
            <DataTable
              columns={columns}
              data={data}
              progressPending={loading}
              // defaultSortFieldId={1}
              // defaultSortAsc={false}
              conditionalRowStyles={conditionalRowStyles}
              pagination
              paginationServer
              paginationTotalRows={totalRows}
              paginationPerPage={perPage}
              onChangeRowsPerPage={onChangeRowsPerPage}
              onChangePage={fetchTeamPage}
              selectableRows={true}
              onSelectedRowsChange={onSelectedRowsChange}
            />
          </div>
        </main>
      </div>
    </div>
  );
}

export default Monitoring;
