import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';

import Logo from '../assets/icon/logo.svg';
import ForgotPassword from '../assets/images/forgot_password.svg';

function ResetPassword() {
  const [email, setEmail] = useState('');

  const validEmail = /.+@.+\.[A-Za-z]+$/.test(email);

  const onClickSendResetPasswordLink = (e) => {
    e.preventDefault();

    if (validEmail) {
      // fetchSendLink();

      window.location.href = '/signin';
    }
  };

  return (
    <main className="bg-white">
      <div className="relative md:flex">
        {/* Content */}
        <div className="md:w-full">
          <div className="min-h-screen h-full flex flex-col after:flex-1">
            {/* Header */}
            <div className="flex-1">
              <div className="flex items-center justify-between h-16 px-4 sm:px-6 lg:px-8">
                {/* Logo */}
                <Link className="block" to="/">
                  <img src={Logo} width="32" height="32" alt="logo" />
                </Link>
              </div>
            </div>

            <div className="max-w-sm w-full mb-24 mx-auto px-4 py-8">
              <img className="mb-12" src={ForgotPassword} alt="world" />

              <h1 className="text-3xl text-slate-800 font-bold mb-6">Reset your Password ✨</h1>
              {/* Form */}
              <form>
                <div className="space-y-4">
                  <div>
                    <label className="block text-sm font-medium mb-1" htmlFor="email">
                      Email Address <span className="text-rose-500">*</span>
                      {validEmail && <span className="ml-2 text-green-500">Valid email</span>}
                      {email.length > 1 && !validEmail && <span className="ml-2 text-red-300">Inalid email</span>}
                    </label>
                    <input
                      id="email"
                      className="form-input w-full"
                      type="email"
                      autoFocus
                      placeholder="input email address"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>
                <div className="flex justify-end mt-6">
                  <button
                    className={
                      validEmail
                        ? 'btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 whitespace-nowrap cursor-pointer'
                        : 'btn bg-gray-500 hover:bg-gray-600 text-white ml-3 whitespace-nowrap'
                    }
                    disabled={validEmail ? false : true}
                    onClick={onClickSendResetPasswordLink}>
                    Send Reset Link
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Toaster />
    </main>
  );
}

export default ResetPassword;
