import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import GoogleMapReact from 'google-map-react';

// const Marker = ({ text }) => <div>{text}</div>;

const EndpMap = (props) => {
  const { lat, lng, name } = props.match.params;

  const pos = {
    lat: parseFloat(lat),
    lng: parseFloat(lng),
  };
  const zoomLevel = 15;

  const handleApiLoaded = (map, maps) => {
    let marker = new maps.Marker({
      position: pos,
      map,
      title: name === 'NONAME' ? '' : name,
    });
  };

  return (
    <div className="flex h-screen w-full overflow-hidden">
      <GoogleMapReact
        bootstrapURLKeys={{ key: 'AIzaSyBcfloHx9Ep3qX2SkJaMJP2-ccfPligATo' }}
        defaultCenter={pos}
        defaultZoom={zoomLevel}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}>
        {/* <Marker lat={lat} lng={lng} text={} /> */}
      </GoogleMapReact>
    </div>
  );
};

export default EndpMap;
