import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

import AppContext from './utils/AppContext';
import encryptStorage from './utils/EncryptStorage';

import './assets/css/style.scss';

import './charts/ChartjsConfig';

// Import pages
import Dashboard from './pages/Dashboard';
import Analytics from './pages/Analytics';
import Signin from './pages/Signin';
import Signup from './pages/Signup';
import ResetPassword from './pages/ResetPassword';
import Monitoring from './pages/Monitoring';
import EndpDevice from './pages/EndpDevice';
import EndpLog from './pages/EndpLog';
import EndpMap from './pages/EndpMap';
import MyTeam from './pages/MyTeam';
import TeamUsers from './pages/TeamUsers';
import TelnetClient from './pages/TelnetClient';
import PageNotFound from './pages/PageNotFound';

function App() {
  const [userInfo, setUserInfo] = useState(encryptStorage.getItem('UserInfo'));
  const [settings, setSettings] = useState(null);

  const location = useLocation();

  const contextData = {
    userInfo,
    setUserInfo,
    settings,
    setSettings,
  };

  // useEffect(() => {
  //   const value = encryptStorage.getItem('UserInfo');
  //   setUserInfo(value);
  // }, []);

  useEffect(() => {
    if (process.env.NODE_ENV !== 'production') console.log('changed userInfo', userInfo);

    if (userInfo) {
      if (process.env.NODE_ENV !== 'production') console.log('save userInfo', userInfo);
      encryptStorage.setItem('UserInfo', JSON.stringify(userInfo));
    }
  }, [userInfo]);

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto';
    window.scroll({ top: 0 });
    document.querySelector('html').style.scrollBehavior = '';
  }, [location.pathname]); // triggered on route change

  return (
    <AppContext.Provider value={contextData}>
      {userInfo?.email ? (
        <Switch>
          {/* <Route exact path="/dashboard" component={Dashboard} /> */}
          {/* <Route exact path="/analytics" component={Analytics} /> */}
          {/* <Route exact path="/monitoring" component={Monitoring} /> */}
          <Route exact path="/" component={EndpDevice} />
          <Route path="/endp/device" component={EndpDevice} />
          <Route path="/endp/log" component={EndpLog} />
          <Route path="/endp/map/:lat/:lng/:name" component={EndpMap} />
          <Route path="/endp/telnet" component={TelnetClient} />
          <Route path="/team/myteam" component={MyTeam} />
          <Route path="/team/teamusers" component={TeamUsers} />
          <Route path="*" component={PageNotFound} />
        </Switch>
      ) : (
        <Switch>
          <Redirect exact from="/" to="/signin" />
          <Route path="/signin" component={Signin} />
          <Route path="/signup" component={Signup} />
          <Route path="/reset-password" component={ResetPassword} />
          <Route path="*" component={PageNotFound} />
        </Switch>
      )}
    </AppContext.Provider>
  );
}

export default App;
